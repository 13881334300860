<template>
  <div class="conteneur">
    <div
      id="gauche"
      v-bind:class="sort.list.length || sort.id != -1 ? 'gauche' : 'gauche-all'"
    >
      <div id="graph"></div>
    </div>
    <div class="vr"></div>
    <div v-if="sort.list.length || sort.id != -1" class="droite">
      <presentationResultatRecherche />
    </div>
  </div>
</template>
<style scoped>
.conteneur {
  display: flex;
  height: calc(100vh - 125px);
}
.gauche-all {
  width: 100%;
  height: calc(100vh - 160px);
}
.gauche {
  width: 50%;
  height: calc(100vh - 160px);
}
.droite {
  width: 50%;
}
</style>
<script>
import ForceGraph from "force-graph";
import { mapState } from "vuex";
import presentationResultatRecherche from "@/components/presentation_resultatRecherche.vue";

let elementResizeDetectorMaker = require("element-resize-detector");

function parser_graph(rParserEtools, reponse_carrot) {
  let nodes = [];
  rParserEtools["response"]["mergedRecords"].forEach((element) => {
    nodes.push({
      id: element.title,
      group: 0,
      myid: element.id,
    });
  });

  let links = [];
  reponse_carrot["clusters"].forEach((clusters) => {
    let name_id = `${clusters["labels"][0]} (${clusters["documents"].length})`;
    nodes.push({
      id: name_id,
      myid: -1,
      documents: clusters["documents"],
      labels: clusters["labels"][0],
    });

    clusters["documents"].forEach((documents) => {
      links.push({
        source: rParserEtools["response"].mergedRecords[documents].title,
        target: name_id,
      });
    });
  });

  return {
    nodes: nodes,
    links: links,
  };
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default {
  name: "graph",
  components: { presentationResultatRecherche },
  data: function () {
    return {
      currentTab: "repons",
      clusters: [],
    };
  },
  computed: {
    ...mapState(["rParserEtools", "reponse_carrot", "sort"]),
  },
  watch: {
    rParserEtools: function () {
      this.myforceGraph();
    },
  },
  methods: {
    myforceGraph: function () {
      const Graph = ForceGraph()(document.getElementById("graph"))
        .nodeLabel("id")
        .linkSource("source")
        .linkTarget("target")
        .linkDirectionalParticles(2)
        .height(700)
        .width(400)
        .nodeAutoColorBy("group")
        .linkHoverPrecision(8)
        .linkDirectionalParticles(1)
        .onNodeClick((node) => {
          if (node.myid == -1) {
            let list = [],
              labels = [];
            list = list.concat(node.documents);
            labels.push(node.title);
            this.$store.commit("set_sort", {
              list: list.filter(onlyUnique),
              labels: labels,
              id: -1,
            });
          } else {
            this.$store.commit("set_sort", {
              list: [],
              labels: [],
              id: node.myid - 1,
            });
          }
        })
        .graphData(parser_graph(this.rParserEtools, this.reponse_carrot));

      elementResizeDetectorMaker().listenTo(
        document.getElementById("gauche"),
        (element) => {
          Graph.width(element.offsetWidth);
          Graph.height(element.offsetHeight);
        }
      );
    },
  },
  mounted: function () {
    this.myforceGraph();
  },
};
</script>
